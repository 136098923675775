import React, { useCallback, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../api/api'


import {
  defaultSuccess,
  defaultCatch
} from '../../../state/actions'
import { connect } from 'react-redux'
import { debounce } from '../../utilities/utilities'

function VisitAutoComplete(props) {
  const {
    label = '',
    defaultSuccess,
    defaultCatch,
    value,
    setValue,
    disabled = false,
    direction = "row",
    justifyContent = "space-between",
    alignItems = "center",
    sx = {}
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const onSearch = inputValue => {
    if (inputValue.length === 0) {
      return setOptions([])
    }

    setLoading(true)
    api.get('employees/search', { params: { prefix: inputValue } })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const { users = [] } = data
        setOptions(users.map(u => ({ label: `${u.first_name} ${u.last_name} - ${u.email}`, id: u.id })))

      })
      .catch(error => defaultCatch(error, navigate))
      .finally(() => setLoading(false))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEfficientSearch = useCallback(
    debounce((nextValue) => onSearch(nextValue), 1000),
    []
  )

  const onChangeInput = ((_, nextIputValue) => {
    setValue(nextIputValue)
    setInputValue(nextIputValue)
    onEfficientSearch(nextIputValue)
  })

  return (
    <Stack
      className='Field'
      gap={2}
      sx={sx}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      <label>{label}</label>
      <Autocomplete
        size='small'
        noOptionsText={t('no options')}
        fullWidth
        disabled={disabled}
        onChange={(_, newValue) => setValue(newValue)}
        value={value}
        onInputChange={onChangeInput}
        inputValue={inputValue}
        options={options}
        loading={loading}
        renderOption={(props, option) => {
          const { label } = option
          return (
            <Box
              component="li"
              {...props}
            >
              <label>{label}</label>
            </Box>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={t('write')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  )
}

const mapDispatchToProps = {
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(VisitAutoComplete)
